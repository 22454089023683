import React from "react";
import { Link } from "react-router-dom";
import SideBar, { SideBarItem } from "./Layout";
import { useLocation } from "react-router-dom";
import {
  ActivitySquare,
  BadgeHelp,
  Building,
  Code2,
  Home,
  Map,
  Newspaper,
  Popcorn,
  Trophy,
  UsersRound,
} from "lucide-react";

const SideBarMain = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  return (
    <div className="flex-initial xxs:hidden lg:flex lg:mt-24 2xl:mt-20 z-10 relative">
      <SideBar>
        {location.pathname === "/news" && type && (
          <Link to="/home">
            <SideBarItem icon={<Home size={25} />} text="Home" />
          </Link>
        )}

        <hr className="mt-5 w-full dark:border-parewacreamcanprimary-100" />
        <Link to="/news?type=arts_%26_culture">
          <SideBarItem
            icon={<UsersRound size={25} />}
            text="Arts & Culture"
            type="arts_%26_culture"
          />
        </Link>
        <Link to="/news?type=business_%26_entrepreneurs">
          <SideBarItem
            icon={<Building size={25} />}
            text="Business & Entrepreneurs"
            type="business_%26_entrepreneurs"
          />
        </Link>
        <Link to="/news?type=celebrity_%26_pop_culture">
          <SideBarItem
            icon={<Popcorn size={25} />}
            text="Celebrity & Pop Culture"
            type="celebrity_%26_pop_culture"
          />
        </Link>
        <Link to="/news?type=fitness_%26_health">
          <SideBarItem
            icon={<ActivitySquare size={25} />}
            text="Fitness & Health"
            type="fitness_%26_health"
          />
        </Link>
        <Link to="/news?type=news_%26_social_concern">
          <SideBarItem
            icon={<Newspaper size={25} />}
            text="News & Social"
            type="news_%26_social_concern"
          />
        </Link>
        <Link to="/news?type=science_%26_technology">
          <SideBarItem
            icon={<Code2 size={25} />}
            text="Science & Technology"
            type="science_%26_technology"
          />
        </Link>
        <Link to="/news?type=sports">
          <SideBarItem
            icon={<Trophy size={25} />}
            text="Sports"
            type="sports"
          />
        </Link>
        <Link to="/news?type=travel_%26_adventure">
          <SideBarItem
            icon={<Map size={25} />}
            text="Travel & Adventure"
            type="travel_%26_adventure"
          />
        </Link>
        <hr className="mt-10 w-full dark:border-parewacreamcanprimary-100" />
        <div className="relative top-100 my-10 ">
          <Link to="/verify-page">
            <SideBarItem icon={<BadgeHelp size={25} />} text="Verify Page" />
          </Link>
          <SideBarItem
            icon={
              <svg
                width="30"
                fill="currentColor"
                height="30"
                // className="h-7 w-7"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1088 1256v240q0 16-12 28t-28 12h-240q-16 0-28-12t-12-28v-240q0-16 12-28t28-12h240q16 0 28 12t12 28zm316-600q0 54-15.5 101t-35 76.5-55 59.5-57.5 43.5-61 35.5q-41 23-68.5 65t-27.5 67q0 17-12 32.5t-28 15.5h-240q-15 0-25.5-18.5t-10.5-37.5v-45q0-83 65-156.5t143-108.5q59-27 84-56t25-76q0-42-46.5-74t-107.5-32q-65 0-108 29-35 25-107 115-13 16-31 16-12 0-25-8l-164-125q-13-10-15.5-25t5.5-28q160-266 464-266 80 0 161 31t146 83 106 127.5 41 158.5z"></path>
              </svg>
            }
            text="Support"
          />
        </div>
      </SideBar>
    </div>
  );
};

export default SideBarMain;
