import { motion } from "framer-motion";
import CardButtons from "../CardButtons/CardButtons.jsx";
import { ToTitleCase } from "./utils/ToTitleCase.js";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;

const parewaImg = `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`;

const kathmanduPost = `${bucketUrl}/backend/assets/portalLogos/TKP.png`;
const annapurnaPost = `${bucketUrl}/backend/assets/portalLogos/अन्_नपूर्ण_पोस्ट्.png`;
const makaluKhabar = `${bucketUrl}/backend/assets/portalLogos/makalukhabar.jpg`;
const setopati = `${bucketUrl}/backend/assets/portalLogos/setopati.png`;
const gorkhapatra = `${bucketUrl}/backend/assets/portalLogos/gorkhapatra.png`;
const nagarikNews = `${bucketUrl}/backend/assets/portalLogos/nagarik_news.png`;
const imageKhabar = `${bucketUrl}/backend/assets/portalLogos/imagekhabar.jpg`;
const karobardaily = `${bucketUrl}/backend/assets/portalLogos/karobardaily.png`;
const himalayaTimes = `${bucketUrl}/backend/assets/portalLogos/eHimalayaTimes.png`;
const aawaajnews = `${bucketUrl}/backend/assets/portalLogos/aawajnews.png`;
const english_makalu_khabar = `${bucketUrl}/backend/assets/portalLogos/english_makalu_khabar.png`;
const rajdhanidaily = `${bucketUrl}/backend/assets/portalLogos/rajdhanidaily.png`;
const onlinekhabar = `${bucketUrl}/backend/assets/portalLogos/onlinekhabar.png`;
const newsOfNepal = `${bucketUrl}/backend/assets/portalLogos/newsofnepal.png`;
const news24 = `${bucketUrl}/backend/assets/portalLogos/news24.jpg`;
const nepalnews = `${bucketUrl}/backend/assets/portalLogos/nepalnews.jpg`;
const himalkhabar = `${bucketUrl}/backend/assets/portalLogos/himalkhabar.png`;
const hamrokhelkud = `${bucketUrl}/backend/assets/portalLogos/hamrokhelkud.jpg`;
const techmandu = `${bucketUrl}/backend/assets/portalLogos/techmandu.jpg`;
const ratopati = `${bucketUrl}/backend/assets/portalLogos/ratopati.jpeg`;
const therisingNepal = `${bucketUrl}/backend/assets/portalLogos/therisingnepal.png`;
const thehimalayantimes = `${bucketUrl}/backend/assets/portalLogos/thehimalayantimes.png`;
const dekhapadhi = `${bucketUrl}/backend/assets/portalLogos/dekhapadhi.png`;
const drishtinews = `${bucketUrl}/backend/assets/portalLogos/drishtinews.jpg`;
const reportersnepal = `${bucketUrl}/backend/assets/portalLogos/reportersNepal.png`;
const khabarhub = `${bucketUrl}/backend/assets/portalLogos/khabarhub.png`;
const ditible = `${bucketUrl}/backend/assets/portalLogos/ditible.webp`;
const bizmandu = `${bucketUrl}/backend/assets/portalLogos/bizmandu.ico`;
const clickmandu = `${bucketUrl}/backend/assets/portalLogos/clickmandu.jpg`;

const sourceImages = {
  "Aawaaj News": aawaajnews,
  "Kathmandu Post": kathmanduPost,
  "Annapurna Post": annapurnaPost,
  "Makalu Khabar English": english_makalu_khabar,
  "Makalu Khabar Nepali": makaluKhabar,
  "Online Khabar English": onlinekhabar,
  "Online Khabar Nepali": onlinekhabar,
  Setopati: setopati,
  Gorkhapatra: gorkhapatra,
  "Nagarik News": nagarikNews,
  "Himalaya Times": himalayaTimes,
  "Karobar Daily": karobardaily,
  "Image Khabar": imageKhabar,
  "Rajdhani Daily": rajdhanidaily,
  "News of Nepal": newsOfNepal,
  "News 24": news24,
  "Nepal News": nepalnews,
  "Himal Khabar": himalkhabar,
  "Hamro Khelkud": hamrokhelkud,
  Techmandu: techmandu,
  Ratopati: ratopati,
  "The Rising Nepal": therisingNepal,
  "The Himalayan Times": thehimalayantimes,
  Dekhapadhi: dekhapadhi,
  "Drishti News": drishtinews,
  "Reporters Nepal": reportersnepal,
  Khabarhub: khabarhub,
  "The Ditible": ditible,
  Bizmandu: bizmandu,
  Clickmandu: clickmandu,
  // add other source_name: image pairs as needed
};

const NewsWithoutImage = ({
  news,
  onLikeNews,
  onSaveNews,
  showLoginAlert,
  setShowLoginAlert,
  setButtonName,
  buttonName,
}) => {
  return (
    <motion.div
      key={news._id}
      className="flex w-full h-full rounded-lg hover:shadow-md dark:hover:shadow-md dark:hover:shadow-zinc-700 dark:bg-zinc-800 bg-zinc-100"
      initial={{ y: -150 }}
      animate={{ y: 0 }}
      transition={{
        type: "spring",
        stiffness: 120,
      }}
    >
      <div className="flex-col p-2 flex font-sans justify-between w-full">
        <div className="p-4 flex-col w-full">
          <span className="news-card-category-body">
            {ToTitleCase(news.type || news.category[0] || "Thinking")}
          </span>
          <h2 className="flex news-card-title mt-4 mb-2">
            {news.title
              ? new DOMParser()
                  .parseFromString(news.title, "text/html")
                  .body.textContent.split(" ")
                  .slice(0, 20)
                  .join(" ")
              : ""}
          </h2>
          <p className="tracking-wider news-card-description 2xl:h-40 xxs:h-20">
            {news.description
              ? new DOMParser()
                  .parseFromString(news.description, "text/html")
                  .body.textContent.slice(0, 70) + "..."
              : ""}
          </p>
        </div>
        <div className="flex flex-col p-2 gap-2">
          <div className="flex justify-between items-center flex-wrap pb-2 border-b-2 border-zinc-300 dark:border-zinc-700">
            <a
              className="news-card-learn-more-button"
              href={`${news.source_url}`}
              target="_blank"
              rel="noreferrer"
            >
              Learn More
              <svg
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </a>
            {/* <button
              title="Verify Article"
              onClick={() => {
                navigate(`/verify-article/${news._id}`);
              }}
              className="text-black dark:text-zinc-200 mr-3 inline-flex items-center ml-auto leading-none text-sm pr-3 py-1 border-r border-zinc-200 dark:border-black"
            >
              <InfoCircle size={20} />
            </button> */}
          </div>
          <div className="flex xxs:flex-col md:flex-row lg:flex-col xl:flex-col 2xl:flex-row gap-2 w-full justify-between font-Comfortaa">
            <div className="w-full mt-2">
              <div className="items-center flex w-fit">
                <img
                  alt="blog"
                  src={sourceImages[news.source_name] || parewaImg}
                  className="w-12 h-12 mx-1 hover:h-20 hover:w-20 rounded-full object-contain object-center hover:p-2 duration-300 ease-in-out"
                ></img>
                <div className="flex-col p-1 ml-1">
                  <span className="news-card-source text-xs sm:text-xs md:text-sm lg:text-md xl:text-md">
                    {news.source_name}
                  </span>
                  <div className="text-zinc-400 tracking-widest flex md:flex-col lg:flex-row">
                    <span className="mr-2 text-xs">
                      {new Date(news.pub_date).toLocaleDateString()}
                    </span>
                    {news.source_name !== "Annapurna Post" &&
                      news.source_name !== "Kathmandu Post" &&
                      news.source_name !== "Ajako Artha" && (
                        <span className="text-[10px]">
                          {new Date(news.pub_date).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <CardButtons
              // onLikeNews={onLikeNews}
              onSaveNews={onSaveNews}
              news={news}
              showLoginAlert={showLoginAlert}
              setShowLoginAlert={setShowLoginAlert}
              setButtonName={setButtonName}
              buttonName={buttonName}
              className="flex items-center mt-2 justify-end xxs:justify-evenly lg:justify-evenly xl:justify-evenly gap-2 lg:w-full xl:w-full 2xl:w-1/2 text-zinc-400 "
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default NewsWithoutImage;
