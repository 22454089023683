import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { newsApi } from "../components/services/api/newsServices.js";
import authSlice from "../slice/authSlice";
import { usersApi } from "../components/services/api/usersService.js";
import likedNewsSlice from "../slice/likedNewsSlice.js";

//Initialise the store
export const store = configureStore({
  reducer: {
    auth: authSlice,
    likedNews: likedNewsSlice,
    [newsApi.reducerPath]: newsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      newsApi.middleware,
      usersApi.middleware,
      thunk,
    ]),
  devTools: false,
});
