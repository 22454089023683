import { motion } from "framer-motion";
import { Filter } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../assets/output.css";
import { setShowModal } from "../../slice/authSlice.js";
import LoadingNew from "../LoadingNew.jsx";
import SideBarMain from "../SideBar/SideBarMain.jsx";
import {
  useGetNewsPaginationQuery,
  useGetNewsWithImagesQuery,
} from "../services/api/newsServices.js";
import { hitApi } from "../services/hitApi.js";
import EverythingSinceALongTimeAgo from "./HeroSection/EverythingSinceALongTimeAgo.jsx";
import HeroSection from "./HeroSection/HeroSectionNew.jsx";
import LoginModal from "./Modals/LoginModal.jsx";
import SuccessfulAlert from "./Modals/SuccessfulAlert.jsx";
import NewsWithImage from "./NewsCard/NewsWithImage.js";
import NewsWithoutImage from "./NewsCard/NewsWithoutImage.js";

const bucketUrl = `${process.env.REACT_APP_BUCKET_URL}/backend/assets/portalLogos`;
const iconMapping = {
  "Kathmandu Post": `${bucketUrl}/TKP.png`,
  "Annapurna Post": `${bucketUrl}/अन्_नपूर्ण_पोस्ट्.png`,
  "Makalu Khabar Nepali": `${bucketUrl}/makalukhabar.jpg`,
  Setopati: `${bucketUrl}/setopati.png`,
  Gorkhapatra: `${bucketUrl}/gorkhapatra.png`,
  "Nagarik News": `${bucketUrl}/nagarik_news.png`,
  "Image Khabar": `${bucketUrl}/imagekhabar.jpg`,
  "Karobar Daily": `${bucketUrl}/karobardaily.png`,
  "Himalaya Times": `${bucketUrl}/eHimalayaTimes.png`,
  "Aawaaj News": `${bucketUrl}/aawajnews.png`,
  "Makalu Khabar English": `${bucketUrl}/english_makalu_khabar.png`,
  "Rajdhani Daily": `${bucketUrl}/rajdhanidaily.png`,
  "Online Khabar English": `${bucketUrl}/onlinekhabar.png`,
  "Online Khabar Nepali": `${bucketUrl}/onlinekhabar.png`,
  "News of Nepal": `${bucketUrl}/newsofnepal.png`,
  "News 24": `${bucketUrl}/news24.jpg`,
  "Nepal News": `${bucketUrl}/nepalnews.jpg`,
  "Himal Khabar": `${bucketUrl}/himalkhabar.png`,
  "Hamro Khelkud": `${bucketUrl}/hamrokhelkud.jpg`,
  Techmandu: `${bucketUrl}/techmandu.jpg`,
  Ratopati: `${bucketUrl}/ratopati.jpeg`,
  "The Rising Nepal": `${bucketUrl}/therisingnepal.png`,
  "The Himalayan Times": `${bucketUrl}/thehimalayantimes.png`,
  Dekhapadhi: `${bucketUrl}/dekhapadhi.png`,
  "Drishti News": `${bucketUrl}/drishtinews.jpg`,
  "Reporters Nepal": `${bucketUrl}/reportersNepal.png`,
  Khabarhub: `${bucketUrl}/khabarhub.png`,
  "The Ditible": `${bucketUrl}/ditible.webp`,
  Bizmandu: `${bucketUrl}/bizmandu.ico`,
  Clickmandu: `${bucketUrl}/clickmandu.jpg`,
};

const HomePAGE = () => {
  const [selectedSourceName, setSelectedSourceName] = useState("All");
  const [sortOption, setSortOption] = useState("default");
  const [activeButton, setActiveButton] = useState("All");
  const [finalNewsData, setFinalNewsData] = useState([]);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [buttonName, setButtonName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const showModal = useSelector((state) => state.auth.showModal);

  let user =
    JSON.parse(localStorage.getItem("user")) ||
    JSON.parse(sessionStorage.getItem("user"));

  let query = {
    page: currentPage,
    sortOption,
  };

  if (selectedSourceName !== "All") {
    query.source_name = selectedSourceName;
  }

  let { isError, isSuccess, isLoading, data, error } =
    useGetNewsPaginationQuery(query);

  let {
    isError: isErrorImageQuery,
    // isSuccess: isSuccessImageQuery,
    // isLoading: isLoadingImageQuery,
    data: dataImageQuery,
    error: errorImageQuery,
  } = useGetNewsWithImagesQuery();

  // ---- NOTE ---- data.newsResults is the array of news!!
  let news = data?.newsResults || [];
  let totalPage = data?.totalPages || 0;

  let newsWithimage = dataImageQuery?.data || [];

  // Separate useEffect for resetting state
  useEffect(() => {
    setFinalNewsData([]);
    setCurrentPage(1);
  }, [selectedSourceName, sortOption]);

  useEffect(() => {
    if (isSuccess) {
      setFinalNewsData((prevNews) => {
        // If current page is 1 return the news that is fetched.
        if (currentPage === 1) {
          return news;
        }
        // Filter out duplicates
        const newNews = news.filter(
          (newArticle) =>
            !prevNews.some((prevArticle) => prevArticle._id === newArticle._id)
        );
        return [...prevNews, ...newNews];
      });
      setTotalPages(totalPage);
    }

    // if (isSuccessImageQuery) {
    //   !isLoadingImageQuery && console.log("Image Query Success!");
    // }

    // if (isLoading === true) {
    //   console.log("Loading News...");
    // }

    // if (isLoadingImageQuery === true) {
    //   console.log("Loading News with Image...");
    // }

    if (isError) {
      console.log("ERROR Occured! :( ", error?.error);
    }

    if (isErrorImageQuery) {
      console.log(
        "ERROR Occured fetching with Image! :( ",
        errorImageQuery?.errorImageQuery
      );
    }
  }, [
    isSuccess,
    isError,
    error,
    news,
    sortOption,
    selectedSourceName,
    // isSuccessImageQuery,
  ]);

  const saveNews = (newsId) => {
    if (!user) {
      setShowLoginAlert(true);
      return;
    }

    const userId = user._id;

    hitApi
      .post(`/news/save/`, {
        user_id: userId,
        news_id: newsId,
        ACTION: news.isSaved ? "DELETE" : "SAVE",
      })
      .then((res) => {
        setShowModal(true);
        setTimeout(() => setShowModal(false), 3000);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const handleSorting = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const toggleSourceName = (sourceName) => {
    setSelectedSourceName(sourceName);
    setActiveButton(sourceName);
  };

  const buttons = [
    { filter: "All", name: "All News" },
    { filter: "Kathmandu Post", name: "Kathmandu Post" },
    { filter: "Annapurna Post", name: "Annapurna Post" },
    { filter: "Nagarik News", name: "Nagarik News" },
    { filter: "Gorkhapatra", name: "Gorkhapatra" },
    { filter: "Makalu Khabar Nepali", name: "Makalu Khabar Nepali" },
    {
      filter: "Makalu Khabar English",
      name: "Makalu Khabar English",
    },
    { filter: "Setopati", name: "Setopati" },
    { filter: "Himalaya Times", name: "Himalaya Times" },
    { filter: "Karobar Daily", name: "Karobar Daily" },
    { filter: "Image Khabar", name: "Image Khabar" },
    { filter: "Ratopati", name: "Ratopati" },
    { filter: "Online Khabar English", name: "Online Khabar English" },
    { filter: "Online Khabar Nepali", name: "Online Khabar Nepali" },
    { filter: "The Himalayan Times", name: "The Himalayan Times" },
    { filter: "Rajdhani Daily", name: "Rajdhani Daily" },
    { filter: "News 24", name: "News 24" },
    { filter: "Techmandu", name: "Techmandu" },
    { filter: "News of Nepal", name: "News of Nepal" },
    { filter: "Nepal News", name: "Nepal News" },
    { filter: "The Rising Nepal", name: "The Rising Nepal" },
    { filter: "Hamro Khelkud", name: "Hamro Khelkud" },
    { filter: "Himal Khabar", name: "Himal Khabar" },
    { filter: "Aawaaj News", name: "Aawaaj News" },
    { filter: "Dekhapadhi", name: "Dekhapadhi" },
    { filter: "Drishti News", name: "Drishti News" },
    { filter: "Reporters Nepal", name: "Reporters Nepal" },
    { filter: "Khabarhub", name: "Khabarhub" },
    { filter: "Ajako Artha", name: "Ajako Aartha" },
    { filter: "The Ditible", name: "The Ditible" },
    { filter: "BIZMANDU", name: "Bizmandu" },
    { filter: "Clickmandu", name: "Clickmandu" },
    { filter: "Techpana", name: "Techpana" },
  ];

  buttons.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div>
      {isLoading ? (
        <LoadingNew />
      ) : (
        <div className="max-w-screen flex bg-white dark:bg-zinc-900 min-h-screen relative">
          {/* SIDE BAR */}
          <SideBarMain />
          {/* SIDE BAR */}

          {/* BODY */}
          <div className="flex-col m-2 overflow-scroll no-scrollbar relative">
            {/* Feedback FORM */}
            <motion.div
              className="h-fit w-full p-1 xxs:text-xs sm:text-sm dark:text-zinc-300 bg-zinc-100 dark:bg-zinc-800 xxs:mt-16 sm:mt-20 rounded-sm sm:justify-center xxs:block sm:flex gap-1 items-center"
              initial={{ y: -250 }}
              animate={{ y: 0 }}
              transition={{
                delay: 0.2,
                type: "spring",
                stiffness: 120,
                duration: 1.5,
              }}
            >
              We <span className="text-parewacreamcanprimary-200">value</span>{" "}
              your input! Please take a moment to share your thoughts in our
              survey -
              <a
                href="https://forms.gle/w9sm1C2WLyzuLkBE8"
                target="_blank"
                rel="noreferrer"
                className="text-xs text-blue-400 hover:underline ml-1"
              >
                Click here to begin
              </a>
            </motion.div>
            {/* Feedback FORM */}

            {/* HERO SECTION */}
            <HeroSection newsWithimage={newsWithimage} />
            {/* HERO SECTION */}

            {/* Everything since a long time ago */}
            <div className="m-2 max-w-screen flex flex-col gap-2 justify-evenly">
              <h2 className="w-full h-fit flex justify-center xxs:text-base lg:text-xl font-Comfortaa p-2 dark:text-zinc-400 text-black mt-2">
                <span className="hover:text-parewacreamcanprimary-100 hover:cursor-none mr-2">
                  Everything
                </span>{" "}
                since a long time ago
              </h2>
              <EverythingSinceALongTimeAgo
                user={user}
                setButtonName={setButtonName}
                setShowLoginAlert={setShowLoginAlert}
                saveNews={saveNews}
              />
            </div>
            {/* Everything since a long time ago */}

            <div className="flex-col">
              <div className="flex-col">
                {/* NEWS SECTION - LATEST ARTICLES */}
                <div className="flex-1 px-2 w-full h-full mt-10">
                  <h2 className="p-1 xxs:text-lg xxs:uppercase lg:capitalize lg:text-2xl font-Comfortaa text-center my-2 text-black dark:text-zinc-500">
                    Select a{" "}
                    <span className="text-parewacreamcanprimary-200">
                      news source
                    </span>{" "}
                    to explore Nepal's latest news
                  </h2>

                  {/* BUTTONS */}
                  <div className="flex-col items-center mb-5 p-3 shadow-md sticky bg-white dark:bg-zinc-900 top-0 z-10">
                    {/* SORTING Option */}
                    <div className="flex items-center font-Comfortaa bg-zinc-200 dark:bg-zinc-800 rounded-lg w-fit">
                      <div className="p-2 border-r border-zinc-300 dark:border-black flex justify-center">
                        <Filter className="text-zinc-500 dark:text-parewacreamcanprimary-300 xxs:h-2/3 xxs:w-2/3 lg:h-full lg:w-full" />
                      </div>
                      <select
                        id="sort"
                        onChange={(e) => handleSorting(e.target.value)}
                        value={sortOption}
                        className="outline-none text-black xxs:text-sm lg:text-base dark:text-parewacreamcanprimary-200 p-1 hover:text-gray-500 transition duration-200 bg-zinc-200 dark:bg-zinc-800"
                      >
                        <option disabled>Select Filter</option>
                        <option value="default">By Default</option>
                        <option value="title">Title</option>
                        <option value="dateOldest">Date (Oldest First)</option>
                      </select>
                    </div>
                    {/* SORTING Option */}

                    {/* Source Name Buttons */}
                    <div className="flex flex-row justify-start items-center gap-4 w-full overflow-x-scroll overflow-y-hidden no-scrollbar h-fit py-2 dark:text-zinc-400">
                      {buttons.map((button) => (
                        <button
                          key={button.filter}
                          onClick={() => toggleSourceName(button.filter)}
                          className={`dark:hover:bg-zinc-800 hover:bg-zinc-300 px-2 p-1 h-fit text-center w-fit flex xxs:text-sm lg:text-base font-Comfortaa tracking-widee rounded-lg transition-all duration-300 ease-in-out ${
                            activeButton === button.filter
                              ? "bg-zinc-200 dark:bg-zinc-800 text-black dark:text-parewacreamcanprimary-200 rounded-lg px-2"
                              : ""
                          }`}
                        >
                          <span className="w-60 flex xxs:justify-start lg:justify-center items-center">
                            {iconMapping[button.name] && (
                              <img
                                src={iconMapping[button.name]}
                                alt={button.name}
                                className="w-6 h-6 mr-2 rounded-full object-cover"
                              />
                            )}
                            {button.name}
                          </span>
                        </button>
                      ))}
                    </div>
                    {/* Source Name Buttons */}
                  </div>
                  {/* BUTTONS */}

                  {/* NEWS CARDS */}
                  <div className="grid 4xl:grid-cols-4 2xl:grid-cols-3 xl:grid-cols-2 sm:grid-cols-2 grid-cols-1 items-center gap-8 p-2 font-sans h-full">
                    {isLoading ? (
                      <div className="flex justify-center items-center h-screen">
                        <LoadingNew />
                      </div>
                    ) : (
                      finalNewsData.map((item) =>
                        item.source_name === "Himalaya Times" ||
                        item.source_name === "Ratopati" ||
                        item.source_name === "Ajako Artha" ||
                        !item.image_source ? (
                          <NewsWithoutImage
                            key={item._id}
                            news={item}
                            onSaveNews={saveNews}
                            showLoginAlert={showLoginAlert}
                            setShowLoginAlert={setShowLoginAlert}
                            setButtonName={setButtonName}
                            buttonName={buttonName}
                          />
                        ) : (
                          <NewsWithImage
                            key={item._id}
                            news={item}
                            onSaveNews={saveNews}
                            showLoginAlert={showLoginAlert}
                            setShowLoginAlert={setShowLoginAlert}
                            setButtonName={setButtonName}
                            buttonName={buttonName}
                          />
                        )
                      )
                    )}
                  </div>
                  {/* NEWS CARDS */}
                </div>
                {/* NEWS SECTION - LATEST ARTICLES */}
              </div>

              {/* Show More Button */}
              {currentPage < totalPages && (
                <div className="w-full mt-4 p-2 mb-2 rounded-lg font-Comfortaa">
                  <div className="flex justify-center">
                    <button
                      onClick={() => setCurrentPage((prev) => prev + 1)}
                      className="px-4 py-2 bg-zinc-100 dark:bg-zinc-900 bg-gradient-to-t from-zinc-100 dark:from-zinc-800 to-zinc-50 dark:to-zinc-900 shadow-lg text-black dark:text-white rounded-full hover:bg-opacity-50 hover:text-opacity-25 dark:hover:bg-opacity-50 dark:hover:text-opacity-25"
                    >
                      Show More
                    </button>
                  </div>
                </div>
              )}
              {/* Show More Button */}
            </div>
          </div>
          {/* BODY */}

          {showModal && (
            <div className="fixed top-auto left-auto inset-2 z-50 max-h-fit max-w-sm">
              <SuccessfulAlert buttonName={buttonName} />
            </div>
          )}

          {showLoginAlert && (
            <div className="fixed inset-2 z-50 max-h-full  rounded-lg max-w-screen bg-zinc-100 dark:bg-zinc-900 dark:bg-opacity-90 bg-opacity-80 font-Comfortaa flex items-center justify-center">
              <div className="h-fit flex flex-col items-center bg-parewaBackground bg-no-repeat bg-left">
                <div className="flex p-6 w-full justify-center bg-zinc-100 dark:bg-black dark:bg-opacity-80 text-black dark:text-white shadow-md mb-2 rounded-lg">
                  You are about to{" "}
                  <span className="text-parewacreamcanprimary-200 mr-1 ml-1">
                    {buttonName}
                  </span>{" "}
                  a news article
                </div>
                <LoginModal setShowLoginAlert={setShowLoginAlert} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HomePAGE;
