import React, { useEffect, useState } from "react";
import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from "../../../services/api/usersService";
import { LogOut } from "lucide-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogout, setSavedNewsInStore } from "../../../../slice/authSlice";
import { hitApi } from "../../../services/hitApi";
import PersonalDetailsWithFormik from "./PersonalDetailsWithFormik";
import useToken from "../../../../hooks/useToken";
import {
  setLikedNews,
  setTotalDislikes,
  setTotalLikes,
} from "../../../../slice/likedNewsSlice";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const photo = `${bucketUrl}/backend/assets/parewa_logo1.PNG`;

const UserSettingsRTK = ({ user, setActiveComponent }) => {
  const token = useToken();
  const [file, setFile] = useState(null);
  const [buttonText, setButtonText] = useState("Submit");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState(photo);

  let id = user?._id;

  let {
    // isLoading: isLoadingGetUsers,
    isSuccess: isSucessGetUsers,
    isError: isErrorGetUsers,
    error: errorGetUsers,
    data: dataGetUsers,
  } = useGetUserByIdQuery({ id, token });

  let [
    updateUser,
    {
      // isLoading: isLoadingUpdateData,
      isSuccess: isSuccessUpdateData,
      isError: isErrorUpdateData,
      error: errorUpdateData,
    },
  ] = useUpdateUserMutation();

  let singleUser = dataGetUsers?.data || {};

  // console.log(singleUser, "Esma aaunu parne ho Image?");

  const [userDetails, setUserDetails] = useState({
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    occupation: "",
    phoneNumber: "",
    education: "",
    location: {
      country: "",
      city: "",
      address: "",
      postCode: "",
    },
    about: "",
    userImage: "",
  });

  useEffect(() => {
    if (isSucessGetUsers) {
      // console.log("User here from RTK!");
      getImage();
      setUserDetails({
        username: singleUser.username,
        email: singleUser.email,
        firstName: singleUser.firstName,
        lastName: singleUser.lastName,
        occupation: singleUser.occupation,
        phoneNumber: singleUser.phoneNumber,
        education: singleUser.education,
        location: {
          country: singleUser.location?.country,
          city: singleUser.location?.city,
          address: singleUser.location?.address,
          postCode: singleUser.location?.postCode,
        },
        about: singleUser.about,
        userImage: singleUser.userImage,
      });
    }
    if (isSuccessUpdateData) {
      setButtonText("Updated successfully!");
      setTimeout(() => setButtonText("Submit"), 3000);
    }

    if (isErrorGetUsers) {
      console.log("Error getting User here!", errorGetUsers.error);
    }
    if (isErrorUpdateData) {
      console.log("Error updating User here!", errorUpdateData);
    }
  }, [
    isSucessGetUsers,
    isErrorGetUsers,
    errorGetUsers,
    isSuccessUpdateData,
    isErrorUpdateData,
    errorUpdateData,
    singleUser,
  ]);

  useEffect(() => {});

  const onSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Updating...");

    // Trim whitespace from each user detail if it exists
    const trimmedUserDetails = {
      ...userDetails,
      username: userDetails.username?.trim() || "",
      email: userDetails.email?.trim() || "",
      firstName: userDetails.firstName?.trim() || "",
      lastName: userDetails.lastName?.trim() || "",
      occupation: userDetails.occupation?.trim() || "",
      phoneNumber: userDetails.phoneNumber, // Assuming phoneNumber is a string and does not need trimming
      education: userDetails.education?.trim() || "",
      location: {
        country: userDetails.location.country?.trim() || "",
        city: userDetails.location.city?.trim() || "",
        address: userDetails.location.address?.trim() || "",
        postCode: userDetails.location.postCode, // Assuming postCode does not need trimming
      },
      about: userDetails.about?.trim() || "",
      userImage: userDetails.userImage?.trim() || "",
    };

    if (file) {
      // Check if a file has been selected
      handleUpload();
    }
    await updateUser({
      body: { _id: id, data: trimmedUserDetails },
      token: token,
    });
  };

  const handleUpload = async (e) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("userId", id); // Add user ID to the request body

    await hitApi
      .post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((error) => {
        console.log("ERROR uploading IMAGE", error.message);
      });
  };

  const getImage = async () => {
    try {
      const response = await hitApi.get(`/userimage/${id}`, {
        responseType: "blob",
      });
      const imageBlob = response.data;
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setUserImage(imageObjectURL);
    } catch (error) {
      console.log("Error getting Image here!", error);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(setLogout());
    dispatch(setSavedNewsInStore([]));
    dispatch(setLikedNews([]));
    dispatch(setTotalLikes(0));
    dispatch(setTotalDislikes(0));
    // Navigate to home page
    navigate("/home");
  };

  return (
    <div className="flex flex-col bg-opacity-25 mt-2 mb-2 max-w-screen mx-auto">
      <PersonalDetailsWithFormik
        singleUser={singleUser}
        userDetails={userDetails}
        setUserDetails={setUserDetails}
        onSubmit={onSubmit}
      />

      {/* PROFILE PICTURE */}
      <div className="grid gap-y-2 text-sm grid-cols-1 lg:grid-cols-3 px-4 md:p-8 dark:bg-zinc-900 dark:bg-opacity-60">
        <div className="text-black dark:text-parewacreamcanprimary-200">
          <p className="font-bold text-lg uppercase">Profile Picture</p>
        </div>
        <div className="lg:col-span-2">
          <div className="flex p-2 shadow-lg bg-zinc-200 bg-opacity-50 dark:bg-zinc-800 rounded-lg items-center">
            <div className="flex-1 sm:text-lg xxs:text-sm p-2 w-1/2 text-center dark:bg-gradient-to-r from-zinc-800 to-zinc-900 dark:text-white">
              Maybe one with a smile?
            </div>
            <form
              className="flex xxs:flex-col sm:flex-row mx-auto w-1/2 p-2 items-center gap-4"
              onSubmit={onSubmit}
            >
              <div className="m-1">
                <img
                  className="rounded-full relative mb-2"
                  src={userImage}
                  alt=""
                />
              </div>

              <button
                title="Upload Image"
                type="button"
                className="sm:w-64 xxs:w-24 sm:h-80 xxs:h-24 p-2 group bg-zinc-200 dark:bg-zinc-700 hover:border-2 hover:border-zinc-400 dark:hover:border-2 dark:border-black rounded-full relative flex justify-center items-center cursor-pointer transition duration-300 ease-in-out"
                onClick={(event) => {
                  document.getElementById("fileInput").click();
                }}
              >
                <img
                  className="hidden group-hover:block w-12 text-black"
                  src="https://www.svgrepo.com/show/33565/upload.svg"
                  alt=""
                />
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    setFile(event.target.files[0]);
                    console.log(event.target.files[0]);
                  }}
                />
              </button>
            </form>
          </div>
        </div>
        <hr className="mt-10 border-b-2 dark:border-b rounded-full dark:rounded-none border-zinc-300 dark:border-zinc-800"></hr>
      </div>
      {/* PROFILE PICTURE */}

      {/* SIGN OUT */}
      <div className="flex text-sm dark:bg-zinc-900 dark:bg-opacity-60">
        <button
          className="text-zinc-800 text-xs gap-2 flex items-center transition-colors duration-300 transform dark:text-zinc-200 px-8 xxs:mt-4 lg:mt-0"
          onClick={handleLogout}
        >
          <LogOut size={14} />
          <span>Sign Out</span>
        </button>
      </div>
      {/* SIGN OUT */}

      {/* Password */}
      {/* <div className="grid gap-y-2 text-sm grid-cols-1 lg:grid-cols-3 px-4 md:p-8 dark:bg-zinc-900 dark:bg-opacity-60">
          <div className="text-black dark:text-parewacreamcanprimary-200">
            <p className="font-bold text-lg uppercase">Password</p>
          </div>

          <div className="lg:col-span-2">
            <div className="flex px-4 lg:px-10 py-10 pt-0 shadow-lg bg-zinc-200 dark:bg-zinc-800 rounded-lg">
              <form className="m-2 w-full">
                <h6 className="text-black dark:text-parewacreamcanprimary-100 text-sm mb-5 font-bold mt-6 bg-zinc-300 dark:bg-zinc-700 w-fit p-2 rounded-lg">
                  Update your Password
                </h6>

                <div className="flex flex-wrap mt-5">
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="text-black dark:text-white text-xs"
                        htmlFor="currentPassword"
                      >
                        Current Password
                      </label>

                      <input
                        type="password"
                        name="currentPassword"
                        id="currentPassword"
                        className="px-3 py-3 text-black text-md dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                        placeholder={singleUser.password}
                      ></input>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="text-black dark:text-white text-xs"
                        htmlFor="newPassword"
                      >
                        New Password
                      </label>

                      <input
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        className="px-3 py-3 text-black text-md dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                      ></input>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="text-black dark:text-white text-xs"
                        htmlFor="confirmNewPassword"
                      >
                        Confirm New Password
                      </label>

                      <input
                        type="password"
                        name="confirmNewPassword"
                        id="confirmNewPassword"
                        className="px-3 py-3 text-black text-md dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                      ></input>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            
          </div>
        </div> */}
      {/* Password */}

      <footer className="mx-auto p-2 mt-4 flex gap-4 text-black font-MontserratAlternates ">
        <button
          className="button bg-zinc-100 tracking-widest p-2 w-2/3 rounded-lg dark:bg-zinc-800 dark:text-parewacreamcanprimary-100 shadow-md hover:shadow-xl dark:hover:scale-105 duration-300 px-4"
          type="submit"
          onClick={() => {
            setActiveComponent("Profile");
          }}
        >
          Cancel
        </button>
        <button
          className="button bg-zinc-100 tracking-widest w-2/3 p-2 rounded-lg dark:bg-zinc-800 dark:text-parewacreamcanprimary-100 shadow-md hover:shadow-xl dark:hover:scale-105 duration-300 px-4"
          type="submit"
          onClick={onSubmit}
        >
          {buttonText}
        </button>
      </footer>
    </div>
  );
};

export default UserSettingsRTK;
