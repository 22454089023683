import { Home, Moon, SplitSquareHorizontal, Sun } from "lucide-react";
import NepaliDate from "nepali-date";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMode } from "../slice/authSlice.js";
import { htmlDateFormat } from "../utlis/htmlDateFormat.js";
import SearchBar from "./SearchBar/SearchBar.js";
import TopNav from "./HomePage/TopNav/TopNav.jsx";
import { DividerTall } from "@styled-icons/fluentui-system-filled/DividerTall";
import { motion } from "framer-motion";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/parewa_logo1.PNG`;

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const [temperature, setTemperature] = useState(null);
  const theme = useSelector((state) => state.auth.mode); // get theme from Redux store
  const dispatch = useDispatch();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const reduxUser = useSelector((state) => state.auth.user);

  // Get the current UTC date/time
  const now = new Date();

  // Calculate Nepal Standard Time (NST) offset in milliseconds
  // Nepal is +5:45 from UTC, so (5*60 + 45) minutes * 60 seconds * 1000 milliseconds
  const nstOffset = (5 * 60 + 45) * 60 * 1000;

  // Get the UTC time in milliseconds and add the NST offset
  const nstTime = now.getTime() + nstOffset;

  // Adjust the date to NST
  const adjustedDateForNST = new Date(nstTime);

  // Subtract one day from the adjusted date
  // adjustedDateForNST.setDate(adjustedDateForNST.getDate());

  // Now create the NepaliDate object with the adjusted date
  const dateInNepali = new NepaliDate(adjustedDateForNST).format(
    "mmmm d, yyyy"
  );

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  useEffect(() => {
    const storedUser =
      reduxUser ||
      JSON.parse(localStorage.getItem("user")) ||
      JSON.parse(sessionStorage.getItem("user"));
    setUser(storedUser);
  }, [reduxUser]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const toggleTheme = () => {
    dispatch(
      setMode(theme === "light" ? "dark" : "light") // dispatch action to change theme in Redux store
    );
  };

  // Function to disable scrolling
  function disableBodyScroll() {
    document.body.style.overflow = "hidden";
  }

  // Function to enable scrolling
  function enableBodyScroll() {
    document.body.style.overflow = "";
  }

  // Example usage within a component
  const toggleNav = (isOpen) => {
    setIsNavOpen(isOpen); // Assuming setIsNavOpen is your state setter for controlling Nav visibility
    if (isOpen) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  };

  // Make sure to also re-enable scrolling when the component unmounts to clean up
  useEffect(() => {
    return () => enableBodyScroll(); // Cleanup function to re-enable scrolling
  }, []);

  return (
    <div
      className={`max-w-screen w-full h-fit mx-auto ${
        theme === "dark"
          ? "bg-zinc-900 text-white"
          : "bg-parewacreamcanprimary-100"
      } px-3 flex justify-between items-center antialiased fixed z-20 ${
        theme === "dark" ? "border-2 border-zinc-800" : "shadow-lg"
      }`}
    >
      <nav className="flex items-center justify-between lg:gap-2 w-full">
        {/* LOGO Section */}
        <div className="flex w-fit items-center">
          <img
            src={parewaImg}
            className="flex-initial xxs:w-0 xxs:h-0 sm:w-20 sm:h-20"
            alt="logo"
          />
          <motion.button
            onClick={() => {
              navigate("/home");
            }}
            initial={{ x: -300 }}
            animate={{ x: 0 }}
            transition={{
              delay: 0.2,
              type: "spring",
              stiffness: 120,
              duration: 1.5,
            }}
            className="xxs:text-lg lg:text-2xl font-MontserratAlternates xxs:font-medium lg:font-semibold uppercase inline-block xxs:p-1 lg:p-0"
          >
            PAREWA
          </motion.button>
        </div>
        {/* LOGO Section */}

        {/* Current Weather and DATE and TIME section */}
        <div
          className={`flex flex-col items-center justify-center ${
            theme === "dark" ? "text-white" : "text-black"
          } font-Comfortaa p-2`}
        >
          <div className="flex md:flex-row justify-center items-center w-full">
            {/* Get LOCAL DATE in English */}
            <div className="flex flex-col text-right">
              <p className="xxs:text-[10px] xs:text-[12px] sm:text-sm xxs:font-medium lg:font-semibold tracking-wider">
                {htmlDateFormat(new Date(), "ne-NP", {
                  timeZone: "Asia/Kathmandu",
                })}
              </p>
            </div>

            <DividerTall size={30} className="text-parewamatrixprimary-300" />

            {/* GET LOCAL DATE in NEPALI */}
            <div className="flex flex-col text-left items-center">
              <p className="xxs:text-[10px] xs:text-[12px] sm:text-sm font-medium tracking-wider">
                {dateInNepali}
              </p>
            </div>
          </div>

          {/* Current TIME */}
          <div className="w-fit flex justify-center ml-6 mt-1">
            <p className="xxs:text-xs xxs:font-medium lg:font-semibold tracking-wider">
              {currentTime.toLocaleTimeString("en-US", {
                hour12: true,
                timeZone: "Asia/Kathmandu",
              })}
            </p>
          </div>
        </div>
        {/* Current Weather and DATE section */}

        {/* SEARCH BAR */}
        {!location.pathname.startsWith("/profile") &&
          !location.pathname.startsWith("/search") && (
            <div className="xxs:hidden lg:flex w-1/4">
              <SearchBar setIsNavOpen={setIsNavOpen} isNavOpen={isNavOpen} />
            </div>
          )}
        {/* 
          This condition checks if the current path does not start with "/profile". 
          If it doesn't, the SearchBar is rendered. 
          If it does start with "/profile", the SearchBar is not rendered. 
      */}

        {/* DARK MODE AND LIGHT MODE Switch */}
        <div className="xxs:hidden lg:flex items-center justify-center">
          <button
            className="hover:scale-105 transition-all hover:shadow-xl duration-300 rounded-full"
            onClick={toggleTheme}
          >
            {theme === "light" ? <Moon size={25} /> : <Sun size={25} />}
          </button>
        </div>

        <div className="xxs:hidden lg:flex flex-col items-center justify-center font-Comfortaa p-2 mr-3">
          <button
            className="font-medium xxs:text-xs 2xl:text-sm hover:opacity-50 rounded-sm p-2"
            onClick={() => {
              if (user) {
                navigate(`/profile/${user._id}`);
              } else {
                navigate("/login");
              }
            }}
          >
            {user ? "Welcome back, " + user.username : "Login"}
          </button>

          {/* The Language part of this section needs to show two buttons: One for English Language: EN and another for Nepali Language: NP */}
          {/* <div className="flex flex-row mt-2 items-center justify-center">
          <button className="text-xs hover:opacity-30">EN</button>
          <GitCommitVertical
            size={20}
            className="text-parewamatrixprimary-300"
          />
          <button className="text-xs hover:opacity-30">NP</button>
        </div> */}
        </div>

        {/* Hamburger Menu */}
        <motion.section
          className="flex lg:hidden max-h-screen max-w-fit p-2"
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          transition={{
            delay: 0.2,
            type: "spring",
            stiffness: 120,
            duration: 1.5,
          }}
        >
          <div
            className="space-y-1 p-1"
            // onClick={() => setIsNavOpen((prev) => !prev)}
            onClick={() => toggleNav(true)}
          >
            <span className="block h-0.5 w-5 animate-pulse bg-zinc-900 dark:bg-white"></span>
            <span className="block h-0.5 w-5 animate-pulse bg-zinc-900 dark:bg-white"></span>
            <span className="block h-0.5 w-5 animate-pulse bg-zinc-900 dark:bg-white"></span>
          </div>

          <div
            className={
              isNavOpen
                ? "absolute right-0 top-0 px-4 min-h-screen overflow-scroll max-w-sm no-scrollbar dark:bg-black bg-zinc-100 z-10 flex flex-col"
                : "hidden"
            }
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            {/* Close Button */}
            <div
              className="absolute top-0 right-2 px-2 py-3"
              // onClick={() => setIsNavOpen(false)}
              onClick={() => toggleNav(false)}
            >
              <svg
                className="h-8 w-8 dark:text-white text-black cursor-pointer"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>

            {/* DATE */}
            <div
              className={`flex md:flex-row items-center xxs:mt-10 xxs:flex lg:hidden justify-center ${
                theme === "dark" ? "text-white" : "text-black"
              } font-Comfortaa p-2`}
            >
              <div className="flex flex-col text-right p-2">
                {/* <p className="text-xs md:text-sm font-medium md:font-semibold">
                          {/* GET Current Local Temperature 
                          Temperature
                        </p> */}

                {/* Get LOCAL Time in English */}
                <p className="xxs:text-[10px] xs:text-[12px] sm:text-sm xxs:font-medium lg:font-semibold tracking-wider">
                  {htmlDateFormat(new Date(), "ne-NP", {
                    timeZone: "Asia/Kathmandu",
                  })}
                </p>
              </div>

              <SplitSquareHorizontal
                size={36}
                className="text-parewamatrixprimary-300"
              />

              <div className="flex flex-col text-left p-2 ">
                {/* <p className="text-xs md:text-sm font-medium md:font-semibold">
                          Cloudy
                        </p> */}

                {/* GET LOCAL DATE in NEPALI */}
                <p className="xxs:text-[10px] xs:text-[12px] sm:text-sm font-medium tracking-wider">
                  {dateInNepali}
                </p>
              </div>
            </div>
            {/* DATE */}

            <div className="w-full font-Comfortaa ml-2 flex justify-center">
              <p className="xxs:text-xs xxs:font-medium lg:font-semibold tracking-wider">
                {currentTime.toLocaleTimeString("en-US", {
                  hour12: true,
                  timeZone: "Asia/Kathmandu",
                })}
              </p>
            </div>

            {/* Categories */}
            {!location.pathname.startsWith("/profile") && <TopNav />}
            {/* Categories */}

            {/* Footer */}
            <ul className="flex flex-col justify-end w-full h-fit font-Comfortaa p-2">
              <h1 className="w-full mx-auto px-2 tracking-wide uppercase flex justify-end items-center text-[10px] dark:text-parewacreamcanprimary-200 text-zinc-600 2xl:hidden xxs:h-5 sm:h-10">
                MORE
              </h1>

              {!location.pathname.startsWith("/profile") && (
                <li className="my-4 w-full uppercase">
                  <div className="xxs:flex lg:hidden w-full items-center gap-4">
                    <SearchBar
                      setIsNavOpen={setIsNavOpen}
                      isNavOpen={isNavOpen}
                      toggleNav={toggleNav}
                    />
                  </div>
                </li>
              )}

              {!location.pathname.startsWith("/profile") && (
                <li className="w-full my-2 uppercase">
                  <div className="flex items-center gap-4">
                    <div
                      onClick={() => {
                        navigate(user ? `/profile/${user._id}` : "/login");
                      }}
                      className="xxs:flex lg:hidden flex items-center justify-center cursor-pointer w-full px-2 bg-white hover:bg-opacity-50 dark:bg-zinc-800 dark:hover:bg-zinc-700 rounded-lg"
                    >
                      <button className="p-2 xxs:text-xs sm:text-sm">
                        {user ? "Welcome back, " + user.username : "Login"}
                      </button>
                    </div>
                  </div>
                </li>
              )}

              {location.pathname.startsWith("/profile") && (
                <li className="my-4 uppercase">
                  <div className="flex items-center justify-center gap-2 w-full">
                    <Home className="w-4 h-4" />
                    <a href="/home" className="text-xs font-semibold mt-1">
                      Go back to Home
                    </a>
                  </div>
                </li>
              )}

              <li className="uppercase w-full border-t-2 flex-col h-fit flex justify-end items-end dark:border-zinc-800 px-2">
                <h1 className="w-full mx-auto tracking-wide uppercase font-Comfortaa flex justify-end items-center text-[10px] dark:text-parewacreamcanprimary-200 text-zinc-600 2xl:hidden h-10">
                  Mode
                </h1>

                <button
                  className="hover:scale-105 transition-all shadow-lg duration-300 rounded-full"
                  onClick={toggleTheme}
                >
                  {theme === "light" ? <Moon size={25} /> : <Sun size={25} />}
                </button>
              </li>

              <li className="uppercase w-full flex-col h-fit flex justify-end items-end px-2">
                <h1 className="w-full mx-auto tracking-wide uppercase font-Comfortaa flex justify-end items-center text-[10px] dark:text-parewacreamcanprimary-200 text-zinc-600 2xl:hidden h-10">
                  Follow Us
                </h1>

                <div className="w-full flex justify-end gap-2">
                  <a
                    href="https://www.instagram.com/parewaainc/"
                    rel="noreferrer"
                    target="_blank"
                    title="Instagram"
                    className="dark:text-parewacreamcanprimary-200 p-1 text-black transition hover:opacity-30"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>

                  <a
                    href="https://www.facebook.com/profile.php?id=61555923404677"
                    title="Facebook"
                    rel="noreferrer"
                    target="_blank"
                    className="dark:text-parewacreamcanprimary-200 p-1 text-black transition hover:opacity-30"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>

                  <a
                    href="https://twitter.com/"
                    rel="noreferrer"
                    target="_blank"
                    title="Twitter"
                    className="dark:bg-zinc-500 rounded-full p-1 transition hover:opacity-30"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      aria-hidden="true"
                      className="h-6 w-6 "
                    >
                      <path d="M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z" />
                    </svg>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </motion.section>
        {/* Hamburger Menu */}
      </nav>
    </div>
  );
};

export default Header;
