import { CircleUserRound, Home, Save, Settings2Icon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useToken from "../../hooks/useToken";
import { useGetUserByIdQuery } from "../services/api/usersService";
import MainHeroSection from "./MainHeroSection";
import { jwtDecode } from "jwt-decode";

const UserProfilePage = () => {
  const token = useToken();
  const { id } = useParams();
  let { refetch, data } = useGetUserByIdQuery({ id, token });
  const theme = useSelector((state) => state.auth.mode);
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState("default");
  const [user, setUser] = React.useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  let userById = data?.data;

  useEffect(() => {
    setUser(userById);
  }, [userById]);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        console.log("Token expired");
        setErrorMessage("Session expired. Redirecting to login page...");
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
      } else {
        // Refetch the user data if the token is valid
        refetch();
      }
    } else {
      navigate("/login");
    }
  }, [navigate, refetch, token]);

  return (
    <>
      {errorMessage ? (
        <div className="w-full h-fit p-6 mt-52 rounded-lg">{errorMessage}</div>
      ) : (
        <div
          className={`min-w-screen flex flex-col xxs:mt-16 sm:mt-20 min-h-screen ${
            theme === "dark" ? "bg-zinc-900" : "bg-white"
          }`}
        >
          <div className="w-full flex justify-center h-fit font-Comfortaa ">
            <nav className="bg-white shadow dark:bg-zinc-900 w-full flex items-center">
              <div className="flex items-center justify-center p-2 mx-auto text-zinc-600 hover:text-opacity-25 capitalize dark:text-zinc-300">
                <button
                  onClick={() => navigate("/home")}
                  className="text-zinc-800 xxs:text-xs sm:text-sm p-1 flex transition-colors duration-300 transform dark:text-zinc-200 mx-1.5 sm:mx-6"
                >
                  <Home className="mr-1 xxs:w-4 xxs:h-4 sm:w-5 sm:h-5" />
                  Home
                </button>

                <button
                  onClick={() => setActiveComponent("Profile")}
                  className="xxs:text-xs sm:text-sm flex p-1 items-center border-b-2 border-transparent hover:text-zinc-800 transition-colors duration-300 transform dark:hover:text-zinc-200 hover:border-zinc-500 mx-1.5 sm:mx-6"
                >
                  <CircleUserRound className="mr-1 xxs:w-4 xxs:h-4 sm:w-5 sm:h-5" />
                  Profile
                </button>

                <button
                  onClick={() => setActiveComponent("Saved")}
                  className="xxs:text-xs sm:text-sm flex p-1 items-center border-b-2 border-transparent hover:text-zinc-800 transition-colors duration-300 transform dark:hover:text-zinc-200 hover:border-zinc-500 mx-1.5 sm:mx-6"
                >
                  <Save className="mr-1 xxs:w-4 xxs:h-4 sm:w-5 sm:h-5" />
                  Saved
                </button>

                <button
                  onClick={() => setActiveComponent("UserSettings")}
                  className="xxs:text-xs sm:text-sm flex p-1 items-center border-b-2 border-transparent hover:text-zinc-800 transition-colors duration-300 transform dark:hover:text-zinc-200 hover:border-zinc-500 mx-1.5 sm:mx-6"
                >
                  <Settings2Icon className="mr-1 xxs:w-4 xxs:h-4 sm:w-5 sm:h-5" />
                  Settings
                </button>
              </div>
            </nav>
          </div>

          {/* BODY */}
          <div
            className={`flex h-fit w-full font-Comfortaa ${
              theme === "dark" ? " bg-zinc-900" : "bg-white"
              // theme === "dark" ? " bg-zinc-900" : "bg-blob bg-no-repeat bg-cover"
            }`}
          >
            {/* HERO SECTION */}

            <MainHeroSection
              activeComponent={activeComponent}
              user={user}
              setActiveComponent={setActiveComponent}
            />

            {/* HERO SECTION */}
          </div>
          {/* BODY */}
        </div>
      )}
    </>
  );
};

export default UserProfilePage;
